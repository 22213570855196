import React from 'react'
import Card from './Card'
import styles from './ZoneCard.module.css'

function ZoneCard(props) {
  const { image, title, label, icon } = props

  return (
    <Card
      image={image}
      style={{ width: '20vh', height: '40vh' }}
    >
      <div className={styles.base}>
        <div className={styles.title}>
          {title}
        </div>

        <img className={styles.icon} src={icon} alt="" />

        <div className={styles.action} onClick={props.onClick}>
          {label || 'Start'}
        </div>
      </div>
    </Card>
  )
}

export default ZoneCard