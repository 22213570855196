import React from 'react'
import './DungeonRewards.css'

const DungeonRewards = (props) => (
  <div className="_rewards">
    {props.rewards.map((reward, idx) => (
      <div key={idx} className={`reward ${reward.active ? `is-active is-${reward.enhancement}` : ''}`} onClick={() => { props.onClick(reward) }}>
        <div className="_chest">
          <i className="custom-icon fl-chest"></i>
          <span className="keycode">{idx + 1}</span>
        </div>
        {reward.active &&
          <div className="_label">
            <span><em>+{reward.value}</em>{reward.name}</span>
          </div>
        }
      </div>
    ))}
  </div>
)

export default DungeonRewards