import React from 'react'
import styles from './Icon.module.css'

import icons from './assets'

function Icon(props) {
  return (
    <div className={styles.base} id={props.id}>
      <img src={icons[props.name]} alt="" />
    </div>
  )
}

export default Icon