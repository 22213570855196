import React from 'react'
import { HeroCard } from 'core/ui'
import AbilityAnimation from './AbilityAnimation'
import FlashDamage from './FlashDamage'

function CharacterBattleCard(props) {
  return (
    <AbilityAnimation
      ability={props.ability}
      inverse={props.isEnemy}
    >
      <FlashDamage
        active={props.isReceivingDamage}
        splash={props.isReceivingDamage}
        damage={props.lastDamageReceived}
        dead={props.isDead}
      >
        <HeroCard
          {...props}
          showTitle={props.myTurn}
          battle={true}
        />
      </FlashDamage>
    </AbilityAnimation>
  )
}

export default CharacterBattleCard