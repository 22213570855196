import React, { useReducer, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import withGame from "core/withGame";
import withPlayer from "core/features/player/hocs/withPlayer";
import arenaReducer, { initialState } from "./arenaReducer";
import { randomCharacter } from "core/store/characters";
import withModals from "core/features/modals/hocs/withModals";

const withBattleArena = Component => props => {
  const { dispatch, staticContext, ...rest } = props;
  const [arena, arenaDispatch] = useReducer(arenaReducer, initialState);
  const speed = 200;
  const wait = 1000;

  useEffect(() => {
    arenaDispatch({ type: "PLAYER_SET", player: randomCharacter() });
    arenaDispatch({ type: "ENEMY_SET", enemy: randomCharacter() });
    arenaDispatch({ type: "PLAYER_TURN" });
  }, []);

  useEffect(() => {
    if (arena.enemy.isDead) return;

    if (arena.enemy.turn > 0) {
      enemyAbility();
    }
  }, [arena.enemy.turn]);

  useEffect(() => {
    if (arena.player.isDealingDamage) return;
    if (arena.player.isDead) return;
    if (arena.enemy.isDead) return;

    if (arena.player.turn > 0) {
      arenaDispatch({ type: "ENEMY_TURN" });
    }
  }, [arena.player.isDealingDamage]);

  useEffect(() => {
    if (arena.enemy.isDealingDamage) return;

    if (arena.enemy.turn > 0) {
      arenaDispatch({ type: "PLAYER_TURN" });
    }
  }, [arena.enemy.isDealingDamage]);

  useEffect(() => {
    if (arena.enemy.remainingHealth > 0) return;

    setTimeout(() => {
      arenaDispatch({ type: "ENEMY_SET", enemy: randomCharacter() });
      // props.openModal('victory')
    }, wait);
  }, [arena.enemy.remainingHealth]);

  useEffect(() => {
    if (arena.player.remainingHealth > 0) return;

    setTimeout(() => {
      props.openModal("defeat");
    }, wait);
  }, [arena.player.remainingHealth]);

  const onAbility = ability => {
    playerAbility(ability);
  };

  const playerAbility = ability => {
    if (
      arena.player.isDealingDamage ||
      arena.enemy.isDealingDamage ||
      arena.enemy.isDead
    )
      return;

    arenaDispatch({ type: "PLAYER_IN", ability });

    setTimeout(() => {
      arenaDispatch({ type: "ENEMY_COLLISION", ability });
    }, ability.animationDuration / 2);

    setTimeout(() => {
      arenaDispatch({ type: "PLAYER_OUT" });
    }, ability.animationDuration);
  };

  const enemyAbility = () => {
    if (
      arena.player.isDealingDamage ||
      arena.enemy.isDealingDamage ||
      arena.enemy.health <= 0
    )
      return;

    const ability = props.player.abilities[0];

    arenaDispatch({ type: "ENEMY_IN", ability });

    setTimeout(() => {
      arenaDispatch({ type: "PLAYER_COLLISION", ability });
    }, ability.animationDuration / 2);

    setTimeout(() => {
      arenaDispatch({ type: "ENEMY_OUT" });
    }, ability.animationDuration);
  };

  if (!arena.player.name && !arena.enemy.name) {
    return "Loading...";
  }

  return <Component {...rest} arena={arena} onAbility={onAbility} />;
};

const mapProps = (state, props) => {
  return {};
};

const mapActions = dispatch => {
  return {};
};

export default compose(
  withGame,
  withPlayer,
  withModals,
  connect(mapProps, mapActions),
  withBattleArena
  // withAuth,
);
