import Abilities from '../../../data/AbilitiesData'
import Assets from './AssetsData'

let roomImages = Assets.rooms
let enemyImages = Assets.enemies
let enemyNames = ['Eyewhip', 'Kakuzu', 'Stok', 'Ovidius', 'Danisha', 'Victorius', 'Skymaul', 'Clearbeard', 'Havencleaver', 'Fuseeyes', 'Saursworn', 'Riverscribe', 'Laughingglory', 'Grassguard', 'Crestlance', 'Bronzesnow', 'Orbmourn', 'Longorb', 'Windless', 'Distantsky', 'Feathersorrow', 'Barleysong', 'Sharpmore', 'Nighttrap', 'Mourninghammer', 'Truebinder', 'Horsewater', 'Bloodlance', 'Slatekeeper', 'Glowgem', 'Emberfeather', 'Keenforest', 'Downsorrow', 'Voidcleaver', 'Doomorb', 'Windshot', 'Steelmantle', 'Lowgrain', 'Foretaker', 'Thundercutter', 'Regaldust', 'Clawflaw', 'Truthhand', 'Dirgeshaper', 'Marshstrength', 'Autumnsnarl']

const Dungeon = {
	level: 1,
	rooms: [],
	image: null,
	currentEnemy: {},
	currentRoom: 0
}

const Rewards = [
	{ enhancement: 'health', value: 100, name: 'Health', active: false },
	{ enhancement: 'damage', value: 20, name: 'Power', active: false },
	{ enhancement: 'defence', value: 50, name: 'Shield', active: false },
	// { enhancement: 'critDamage', value: 20, name: 'Crit Damage', active: false }
	// { enhancement: 'critChance', value: 20, name: 'Crit Chance', active: false }
	// { enhancement: 'hitChance', value: 20, name: 'Hit Chance', active: false }
]

const Enemy = {
	name: 'CoolBot',
	image: enemyImages[0],
	stats: {
		health: 20,
		damage: 2,
		defence: 0,
		hitChance: 30,
		critChance: 1,
		critDamage: 10
	},
	currentDamage: 0,
	currentAbility: Object.assign({}, Abilities.getByName('strike')),
	isElite: false,
	waitingTurn: true,
	dealingDamage: false,
	receivingDamage: false,
	damageArray: []
}

const AI = (level = 1, room = 1, initial = 0) => {
	let exponential = (scale, increment, shape) => {
		return Math.floor(scale * Math.pow(increment, shape))
	}

	let random = (chance, action) => {
		if (Math.floor(Math.random() * 100) <= chance) {
			return action
		} else {
			return 0
		}
	}

	let shapes = {
		expert: {
			// boss: 	1.050,
			boss: 1,
			reward: 1.2,
			stat: 1.1,
			//health:  	1 + level * 0.050,
			//damage:  	1 + level * 0.025,
			//defence: 	1 + level * 0.050,

			health: 1.6,
			damage: 1.4,
			defence: 1.6
		}
	}

	return {
		expert: {
			// boss: 	Math.floor(Math.pow(level, shapes.expert.boss)),
			boss: Math.floor(level + shapes.expert.boss),
			reward: Math.floor(Math.pow(level, shapes.expert.reward)),
			// stat: 	Math.floor(Math.pow(initial + level + room, shapes.expert.stat)),
			// health:  Math.floor(Math.pow(initial + level + room, shapes.expert.health)),
			// damage:  Math.floor(Math.pow(initial + level + room, shapes.expert.damage)),
			// defence: Math.floor(Math.random()*100) > 50 ? Math.floor(Math.pow(initial + level + room, shapes.expert.defence)) : 0,

			stat: exponential(initial, level, shapes.expert.stat),
			health: exponential(initial, level, shapes.expert.health),
			damage: exponential(initial, level, shapes.expert.damage),
			defence: random(100, exponential(initial, level, shapes.expert.defence)),

			rooms: Math.min(level > 9 ? 5 : 3, 1 + level)
		}
	}
}

// const EnemyKakuzu = Object.assign({}, Enemy, {
// 	name: 'Kakuzu',
// 	health: 200,
// 	damage: 10,
// 	defence: 0,
// 	hitChance: 100,
// 	image: enemyImageKakuzu
// })

const randomEnemy = (level, room, isElite = false) => {
	return {
		...Enemy,
		isElite,
		name: enemyNames[Math.floor(Math.random() * enemyNames.length)],
		image: enemyImages[Math.floor(Math.random() * enemyImages.length)],
		stats: {
			...Enemy.stats,
			damage: AI(level, room, Enemy.stats.damage).expert.damage,
			health: AI(level, room, Enemy.stats.health).expert.health,
			defence: AI(level, room, Enemy.stats.defence).expert.defence,
			critChance: AI(level, room, Enemy.stats.critChance).expert.stat,
			critDamage: AI(level, room, Enemy.stats.critDamage).expert.stat,
		}
	}
}

const randomRooms = (level) => {
	let rooms = []
	let roomsNum = AI(level).expert.rooms
	let bossLevel = AI(level).expert.boss

	for (let i = 1; i <= roomsNum; i++) {
		if (i === roomsNum) {
			rooms.push({ enemy: randomEnemy(bossLevel, i, true) })
			//rooms.push({ enemy: EnemyKakuzu })
		} else {
			rooms.push({ enemy: randomEnemy(level, i) })
		}
	}

	return rooms
}

const randomBackground = () => {
	return roomImages[Math.floor(Math.random() * roomImages.length)]
}

const randomReward = () => {
	return {
		...Rewards[Math.floor(Math.random() * Rewards.length)]
	}
}

export default {
	newDungeon(level = 1) {
		return {
			...Dungeon,
			level,
			rooms: randomRooms(level),
			image: randomBackground(),
		}
	},

	lobbyImage() {
		//
		return Assets.lobby
	},

	getRewards(level) {
		return [
			randomReward(),
			randomReward(),
			randomReward(),
		].map(reward => {
			reward.value += AI(level).expert.reward

			return reward
		})
	}
}
