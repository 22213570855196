import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { scenes } from 'core/store/scenes'
import styles from './Scene.module.css'

function Scene(props) {
  useEffect(() => {
    const scene = scenes.find(s => s.route.path === props.match.path)
    const { route, ...rest } = scene
    props.setScene({ ...rest, title: props.title })
  }, [])

  return (
    <div className={styles.base}>
      <div className={styles.main}>
        <div className={styles.wrapper}>
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default withRouter(connect(
  () => ({}),
  (dispatch) => ({
    setScene: (scene) => dispatch({ type: 'GAME_SET_SCENE', payload: scene }),
  }),
)(Scene))