import React, { useContext } from 'react'
import GameContext from '../GameContext'
import Scene from './Scene'
import Building from '../buildings/Building'

function Town() {
  const game = useContext(GameContext)

  return (
    <Scene>
      <Building
        name="Food Gathering"
        speed={50}
        amount={1000}
        limit={15000}
        icon="food"
        onCollect={amount => game.dispatch({
          type: 'ADD_TO_INVENTORY', payload: {
            key: 'food',
            value: amount,
          }
        })}
      />
      <Building
        name="Stonesmithing"
        speed={50}
        amount={10}
        limit={150}
        icon="stone"
        onCollect={amount => game.dispatch({
          type: 'ADD_TO_INVENTORY', payload: {
            key: 'stone',
            value: amount,
          }
        })}
      />
      <Building
        name="Blacksmithing"
        speed={10}
        amount={5}
        limit={100}
        icon="metal"
        onCollect={amount => game.dispatch({
          type: 'ADD_TO_INVENTORY', payload: {
            key: 'metal',
            value: amount,
          }
        })}
      />
      <Building
        name="Market"
        speed={10}
        amount={5}
        limit={100}
        icon="coin"
        onCollect={amount => game.dispatch({
          type: 'ADD_TO_INVENTORY', payload: {
            key: 'coins',
            value: amount,
          }
        })}
      />
    </Scene>
  )
}

export default Town