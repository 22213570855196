import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { abilities } from "core/store/abilities";

const withPlayer = Component => props => {
  const { dispatch, staticContext, ...rest } = props;
  return <Component {...rest} />;
};

const mapProps = (state, props) => {
  const player = {
    ...state.player,
    abilities: [
      abilities["ability-strike"],
      abilities["ability-shield"],
      abilities["ability-execute"],
    ],
  };
  // const heroesById = state.player.heroes.byId
  // const abilitiesById = state.player.abilities.byId

  // const hero = heroesById[state.player.hero]
  // const abilities = hero.abilities.reduce((acc, id) => {
  //   return acc.concat({
  //     ...abilitiesById[id],
  //     id,
  //   })
  // }, [])

  // const player = {
  //   hero,
  //   abilities,
  // }

  return { player };
};

const mapActions = dispatch => {
  return {};
};

export default compose(connect(mapProps, mapActions), withPlayer);
