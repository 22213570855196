const Abilities = [
  {
    name: 'strike',
    level: 1,
    power: { damage: 100 },
    proc: 0,
    cooldown: 0,
    onCooldown: 0,
    keyChar: 'Q',
    keyCode: 113,
    icon: 'fl-axe',
    animation: 'strike',
    animationDuration: 800
  },
  {
    name: 'execute',
    level: 3,
    power: { damage: 220, lifesteal: 30 },
    proc: 30,
    cooldown: 2,
    onCooldown: 0,
    keyChar: 'W',
    keyCode: 119,
    icon: 'fl-sword-1',
    animation: 'execute',
    animationDuration: 600
  },
  {
    name: 'cripple',
    level: 1,
    power: { damage: 300, stun: 2 },
    proc: 0,
    cooldown: 2,
    onCooldown: 0,
    keyChar: 'E',
    keyCode: 101,
    icon: 'fl-sword-2',
    animation: 'cripple',
    animationDuration: 600
  },
  {
    name: 'hide',
    level: 1,
    power: { stealth: 3 },
    proc: 0,
    cooldown: 4,
    onCooldown: 0,
    keyChar: 'E',
    keyCode: 101,
    icon: 'fl-sword-2',
    animation: 'cripple',
    animationDuration: 600
  },
  {
    name: 'bladestorm',
    level: 1,
    power: { damage: 500 },
    proc: 0,
    cooldown: 5,
    onCooldown: 0,
    keyChar: 'R',
    keyCode: 114,
    icon: 'fl-axes',
    animation: 'bladestorm',
    animationDuration: 1000
  },
  {
    name: 'shield',
    level: 1,
    power: { defence: 10 },
    proc: 0,
    cooldown: 3,
    onCooldown: 0,
    keyChar: 'E',
    keyCode: 101,
    icon: 'fl-shield',
    animation: 'shield',
    animationDuration: 600
  }
]

export default {
  getByName(name) {
    return Abilities.find(ability => ability.name === name)
  }
}
