import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

const withModals = Component => props => {
  const { dispatch, staticContext, ...rest } = props
  return <Component {...rest} />
}

const mapProps = (state, props) => {
  const modals = state.modals

  return { modals }
}

const mapActions = (dispatch) => {
  return {
    openModal: (path, data) => dispatch({ type: 'MODALS_OPEN', payload: { path, data } }),
    closeModal: () => dispatch({ type: 'MODALS_CLOSE' }),
  }
}

export default compose(
  connect(mapProps, mapActions),
  withModals,
)