import React from 'react'
import bg from '../assets/leather.jpg'
import TransformNumber from '../../../common/TransformNumber'
import './Character.css'

const Character = (props) => {
	let characterStyles = {}
	let characterClassNames = []
	let isHavingDebuff = false

	const flipCard = () => {
		props.flipCard(props.isPlayer ? 'player' : 'enemy')
	}

	if (props.currentAbility) {
		characterStyles.animationDuration = `${props.currentAbility.animationDuration}ms`
	}

	if (props.isDead) {
		characterClassNames.push('is-dead')
	}

	if (props.dealingDamage || props.usingAbility) {
		characterClassNames.push(`anim-${props.currentAbility.animation}${!props.isPlayer ? '-inverse' : ''}`)
	}

	if (props.receivingDamage) {
		characterClassNames.push('is-beeing-hit')
	}

	if (props.isElite) {
		characterClassNames.push('is-elite')
	}

	if (props.playerHasDefence) {
		characterClassNames.push('has-defence')
	}

	if (!props.waitingTurn && props.isPlayer) {
		characterClassNames.push('has-turn is-player')
	}

	if (!props.isPlayer && !props.waitingTurn) {
		characterClassNames.push('has-turn is-enemy')
	}

	return (
		<div className={`character ${characterClassNames.join(' ')}`} style={characterStyles}>
			{isHavingDebuff && <div className="_debuff"><span></span></div>}

			<div className={`_card ${props.isCardFlipped ? 'is-flipped' : ''}`}>
				<div className="_flipper" onClick={flipCard}>
					<div className="_frontside">
						<div className="_image" style={{ backgroundImage: `url(${props.image})` }}></div>
					</div>
					<div className="_backside">
						<div className="_stats">
							<div className="_table" style={{ backgroundImage: `url(${bg})` }}>
								<div>
									<span>Power</span>
									<span>{props.stats && props.stats.damage}</span>
								</div>
								<div>
									<span>Hit Chance</span>
									<span>{props.stats && props.stats.hitChance}</span>
								</div>
								<div>
									<span>Critical Chance</span>
									<span>{props.stats && props.stats.critChance}</span>
								</div>
								<div>
									<span>Critical Power</span>
									<span>{props.stats && props.stats.critDamage}</span>
								</div>
								<div className="_last-hit">
									<span>Last Hit</span>
									<span>{props.currentDamage === 0 ? <span>-</span> : props.currentDamage}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="_name">{props.name}</div>

			<div className="_blobs">
				{props.stats && <div className="_health"><TransformNumber value={props.stats.health} /></div>}
				{props.stats && props.stats.defence > 0 && <div className="_defence"><TransformNumber value={props.stats.defence} /></div>}
			</div>
		</div>
	)
}

export default Character