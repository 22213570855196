const initialState = {
  isLoading: false,
  hasLoaded: false,
  isLoggedIn: false,
  error: null,

  scene: {
    header: true,
    footer: true,
  }
}

const gameReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GAME_SET_SCENE':
      return {
        ...state,
        scene: action.payload,
      }

    case 'GAME_LOADING':
      return {
        ...state,
        isLoading: true,
      }

    case 'GAME_ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }

    case 'GAME_SUCCESS':
      return {
        ...state,
        isLoading: false,
        hasLoaded: true,
        isLoggedIn: false,
        error: null,
      }

    case 'GAME_AUTH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        hasLoaded: true,
        isLoggedIn: true,
        error: null,
      }

    default:
      return state
  }
}


export default {
  game: gameReducer
}