import Town from 'scenes/Town'
import Player from 'scenes/Player'
import Heroes from 'scenes/Heroes'
import Expedition from 'scenes/Expedition'
import ExpeditionAdventure from 'scenes/ExpeditionAdventure'
import model from './model'

export default [
  model({
    name: 'town',
    route: {
      path: '/',
      exact: true,
      component: Town,
    },
  }),
  model({
    name: 'player',
    footer: false,
    route: {
      path: '/player',
      component: Player,
    },
  }),
  model({
    name: 'heroes',
    route: {
      path: '/heroes',
      component: Heroes,
    },
  }),
  model({
    name: 'expedition',
    route: {
      exact: true,
      path: '/expedition',
      component: Expedition,
    },
  }),
  model({
    name: 'expedition-adventure',
    route: {
      path: '/expedition/adventure',
      component: ExpeditionAdventure,
    },
  }),
]