import React from 'react'
import { Scene } from 'core/layout'
import withModals from 'core/features/modals/hocs/withModals'
import AdventureMap from 'games/adventure/AdventureMap'

function Expedition(props) {
  return (
    <Scene>
      <AdventureMap />
    </Scene>
  )
}

export default withModals(Expedition)