import React from 'react'
import { Scene } from 'core/layout'

function Player(props) {
  return (
    <Scene title="Player">
      Test
    </Scene>
  )
}

export default Player