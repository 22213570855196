export default (data) => {
  return {
    name: 'default',
    level: 1,
    power: { damage: 100, stun: 2 },
    proc: 30,
    cooldown: 2,
    isOnCooldown: null,
    keyChar: null,
    keyCode: null,
    icon: null,
    animation: null,
    animationDuration: null,
    ...data,
  }
}