import React from 'react'
import styles from './AbilityButton.module.css'

function AbilityButton(props) {
  const { cooldown, onClick, icon } = props

  return (
    <div className={styles.base} onClick={onClick} style={{ backgroundImage: `url(${icon})` }}>
      <div className={styles.frame}>
        {cooldown && <span>{cooldown}</span>}
      </div>
    </div>
  )
}

export default AbilityButton