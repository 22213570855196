import React from 'react'
import styles from './ProgressBar.module.css'

function ProgressBar(props) {
  return (
    <div className={styles.track}>
      <div className={styles.progress} style={{ width: `${props.value}%` }} />
    </div>
  )
}

export default ProgressBar