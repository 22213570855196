import {
  storeToInstall as playerStore,
  sagasToInstall as playerSagas,
} from 'core/features/player'

import {
  storeToInstall as modalsStore,
  sagasToInstall as modalsSagas,
} from 'core/features/modals'

export const stores = {
  ...playerStore,
  ...modalsStore,
}

export const sagas = [
  ...playerSagas,
  ...modalsSagas,
]