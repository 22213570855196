import React from 'react'
import Ability from './Ability'
import LogBox from './LogBox'
import Character from './Character'

const DungeonArena = (props) => (
  <div className="_arena">
    <div className="_battle">
      <div className={`_character is-player ${props.player.usingAbility ? 'is-taking-turn' : ''}`}>
        <Character {...props.player} isPlayer={true} flipCard={props.flipCard} isCardFlipped={props.cardFlipped.player} isDead={props.playerIsDead} playerHasDefence={props.playerHasDefence} />
        {props.player.stats &&
          props.player.damageArray.map((log, idx) => <div className="_splash" key={idx}>-{log}</div>)}
      </div>
      <div className="_log">
        <div>
          {props.logger.map((log, idx) => <LogBox {...log} key={idx} />)}
        </div>
      </div>
      <div className={`_character ${props.dungeon.currentEnemy.dealingDamage ? 'is-taking-turn' : ''}`}>
        <Character {...props.dungeon.currentEnemy} flipCard={props.flipCard} isCardFlipped={props.cardFlipped.enemy} isDead={props.enemyIsDead} />
        {props.dungeon.currentEnemy.stats &&
          props.dungeon.currentEnemy.damageArray.map((log, idx) => <div className="_splash" key={idx}>-{log}</div>)}
      </div>
    </div>

    <div className="_footer">
      <div className="_hero-abilities">
        {props.player.abilities.map((ability, idx) => {
          return (
            <Ability onClick={() => props.useAbility(ability)}
              key={idx}
              ability={ability}
              isDisabled={props.player.usingAbility} />
          )
        })}
      </div>
      <div className="_autofight">
        <a onClick={() => props.setAutoFight()}>{props.autoFight && <span>&#10004;</span>} Auto Attack</a>
      </div>
    </div>
  </div>
)

export default DungeonArena