export const initial = {
  food: 0,
  metal: 0,
  stone: 0,
}

export const reducer = (state, action) => {
  console.group(action.type)
  console.log(action.payload)

  switch (action.type) {
    case 'ADD_TO_INVENTORY':
      const { key, value } = action.payload
      return {
        ...state,
        [key]: state[key] + value,
      }

    default:
      return state
  }
}