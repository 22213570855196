import React from 'react'
import DungeonRooms from './DungeonRooms'

const DungeonHeading = (props) => (
    <div className="_heading">
        <div>
            <h1>Dungeon Level {props.level}</h1>
            {props.currentRoom > 0 && <h2>{props.currentRoom} / {props.rooms.length} Rooms</h2>}
            {props.currentRoom === 0 && <h2>Complete</h2>}

            <DungeonRooms rooms={props.rooms} currentRoom={props.currentRoom} />
        </div>
    </div>
)

export default DungeonHeading