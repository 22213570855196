import Test from 'modals/Test'
import Victory from 'modals/Victory'
import Defeat from 'modals/Defeat'
import model from './model'

export default [
  model({
    path: 'test',
    component: Test
  }),
  model({
    path: 'victory',
    component: Victory
  }),
  model({
    path: 'defeat',
    component: Defeat
  }),
]