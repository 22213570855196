import React from 'react'
import { Modal } from 'core/ui'
import withModals from 'core/features/modals/hocs/withModals'

function Victory(props) {
  const { closeModal } = props

  return (
    <Modal>
      <div onClick={closeModal}>Victory</div>
    </Modal>

  )
}

export default withModals(Victory)