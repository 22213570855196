import React, { useReducer, useEffect } from 'react'
import { Link, Switch, Route } from 'react-router-dom'
import styles from './NewGame.module.css'

import GameContext from './GameContext'
import Town from './scenes/Town'
import Icon from './shared/icons/Icon'
import { reducer, initial } from './store'

function NewGame() {
  const [state, dispatch] = useReducer(reducer, initial)

  useEffect(() => {
    console.info(state)
    console.groupEnd()
  })

  return (
    <GameContext.Provider value={{
      state,
      dispatch,
    }}>
      <div className={styles.base}>
        <div className={styles.board}>
          <div className={styles.boardMain}>
            <Town />
          </div>
        </div>
      </div>
    </GameContext.Provider>
  )
}

export default NewGame