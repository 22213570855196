import Tex_skeleton from '../assets/Tex_skeleton.PNG'
import Tex_swordsman from '../assets/Tex_swordsman.PNG'
import Tex_Warlock from '../assets/Tex_Warlock.PNG'
import Tex_werewolf from '../assets/Tex_werewolf.PNG'

import FantasyCharacters_lizardman from '../assets/FantasyCharacters_lizardman.png'
import FantasyCharacters_Lurker from '../assets/FantasyCharacters_Lurker.png'
import FantasyCharacters_magma_demon from '../assets/FantasyCharacters_magma_demon.png'
import FantasyCharacters_marsh_howler from '../assets/FantasyCharacters_marsh_howler.png'
import FantasyCharacters_Dwarf_warrior_male from '../assets/FantasyCharacters_Dwarf_warrior_male.png'

import enemyImage1 from '../assets/enemy1.png'
import enemyImage2 from '../assets/enemy2.png'
import enemyImage3 from '../assets/enemy3.png'
import enemyImage4 from '../assets/enemy4.png'
import enemyImage5 from '../assets/enemy5.png'
import enemyImage6 from '../assets/enemy6.jpg'
import enemyImage7 from '../assets/enemy7.jpg'
import enemyImage8 from '../assets/enemy8.jpg'
import enemyImage9 from '../assets/enemy9.jpg'
import enemyImage10 from '../assets/enemy10.jpg'
import enemyImage11 from '../assets/enemy11.jpg'
import enemyImageKakuzu from '../assets/kakuzu.png'

import room00Image from '../assets/room00.jpg'
import Tex_portal from '../assets/Tex_portal.PNG'
import room1Image from '../assets/room1.jpg'
import room2Image from '../assets/room2.jpg'
import room3Image from '../assets/room3.jpg'
import room4Image from '../assets/room4.jpg'
import room5Image from '../assets/room5.jpg'
import room6Image from '../assets/room6.jpg'
import room7Image from '../assets/room7.jpg'
import room8Image from '../assets/room8.jpg'
import room9Image from '../assets/room9.jpg'
import room10Image from '../assets/room10.jpg'
import lobbyImage from '../assets/lobby.jpg'

import room01Image from 'games/newgame/shared/cave_bg.jpg'
import room02Image from 'games/newgame/shared/desert_bg.jpg'
import room03Image from 'games/newgame/shared/grassland_bg.jpg'

export default {
    lobby: lobbyImage,
    rooms: [room01Image, room02Image, room03Image],
    // enemies: [],
    // enemies: [FantasyCharacters_Dwarf_warrior_male, FantasyCharacters_lizardman, FantasyCharacters_Lurker, FantasyCharacters_magma_demon, FantasyCharacters_marsh_howler],
    enemies: [Tex_swordsman, Tex_skeleton, Tex_Warlock, Tex_werewolf]
    // rooms: [room00Image, room1Image, room2Image, room3Image, room4Image, room5Image, room6Image, room7Image, room8Image, room9Image, room10Image],
    // enemies: [enemyImageKakuzu, enemyImage1, enemyImage2, enemyImage3, enemyImage4, enemyImage5, enemyImage6, enemyImage7, enemyImage8, enemyImage9, enemyImage10, enemyImage11]
}
