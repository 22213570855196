import React from 'react'
import styles from './Modal.module.css'

function Modal(props) {
  return (
    <div className={styles.base}>
      <div className={styles.modal} style={props.style}>
        {props.children}

        {props.onClose &&
          <div className={styles.close} onClick={props.onClose}>X</div>
        }
      </div>
      <div className={styles.overlay} onClick={props.onClose} />
    </div>
  )
}

export default Modal