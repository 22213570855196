import Abilities from './AbilitiesData'
import playerImage from '../common/images/Tex_Adventurer.PNG'

const Player = {
    name: 'ShowNoFear',
    image: playerImage,
    stats: {
        health: 40,
        maxHealth: 40,
        defence: 0,
        damage: 13,
        hitChance: 30,
        dodgeChance: 30,
        critChance: 20,
        critDamage: 20
    },
    abilities: [
        Object.assign({}, Abilities.getByName('strike')),
        Object.assign({}, Abilities.getByName('execute')),
        Object.assign({}, Abilities.getByName('shield')),
        Object.assign({}, Abilities.getByName('bladestorm'))
    ],
    currentDamage: 0,
    currentAbility: {},
    usingAbility: false,
    receivingDamage: false,
    waitingTurn: false,
    damageArray: []
}

export default {
    newPlayer() {
        return Object.assign({}, Player)
    }
}
