import React from 'react'

const DungeonRooms = (props) => {
    return (
        <div className="_rooms">
            {props.rooms.map((room, idx) => <span className={`${props.currentRoom === 0 || idx < props.currentRoom ? 'is-ended' : '' } ${idx + 1 === props.currentRoom ? 'is-active' : '' }`} key={idx}></span>)}
            <span className={`has-icon ${props.currentRoom === 0 ? 'is-complete' : ''}`}><i className="custom-icon fl-chest"></i></span>
        </div>
    )
}

export default DungeonRooms