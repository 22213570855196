import React from "react";
import { Scene } from "core/layout";
import { Cards, HeroCard } from "core/ui";
import { characters } from "core/store/characters";
import { getRandomItemFromArray } from "core/helpers";

function Heroes(props) {
  const list = getRandomItemFromArray(characters, 10);

  return (
    <Scene title="Heroes">
      <Cards>
        {list.map(hero => (
          <HeroCard key={hero.id} {...hero} />
        ))}
      </Cards>
    </Scene>
  );
}

export default Heroes;
