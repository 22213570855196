import React from 'react'
import './Ability.css'

const Ability = (props) => (
	<a className={`ability-button ${props.isDisabled ? 'is-disabled' : '' }`} onClick={props.onClick} data-keycode={props.ability.keyCode}>
		{props.ability.onCooldown > 0 && <span className="_cooldown">{props.ability.onCooldown}</span>}
		<span className="_icon"><i className={props.ability.icon}></i></span>
        <span className="keycode">{props.ability.keyChar}</span>
		<span className="_level">{props.ability.level}</span>
	</a>
)

export default Ability