import { characters } from 'core/store/characters'
import { abilities } from 'core/store/abilities'
import avatar from 'themes/default/MonstersAvatarIcons_22.PNG'

const initialState = {
  id: '001',
  name: 'Odivi Udius',
  avatar,
  level: 10,
  coins: 1200,
  diamonds: 10,
  // hero: 'hero-skymaul-1',
  // heroes: {
  //   byId: {
  //     'hero-skymaul-1': {
  //       ...characters[0],
  //       abilities: ['ability-strike-1', 'ability-strike-2', 'ability-shield-1'],
  //     },
  //   },
  //   allIds: ['hero-skymaul-1']
  // },
  // abilities: {
  //   byId: {
  //     'ability-strike-1': {
  //       ...abilities['ability-strike'],
  //       _id: 'ability-strike',
  //     },
  //     'ability-strike-2': {
  //       ...abilities['ability-strike'],
  //       _id: 'ability-strike',
  //     },
  //     'ability-shield-1': {
  //       ...abilities['ability-shield'],
  //       _id: 'ability-shield',
  //     },
  //   },
  //   allIds: ['ability-strike-1']
  // },
  // Add powers to upgrade abilities
}

const player = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}
export default {
  player,
}