import React from 'react'
import { ReactComponent as SplashSVG } from 'themes/default/splash.svg'

function FlashDamage(props) {
  const { active, dead, children, splash, damage } = props

  return (
    <div className={`
      ${active ? 'anim-flash' : ''}
      ${dead ? 'anim-dead' : ''}
    `} style={{
        position: 'relative'
      }}>
      {children}

      {splash &&
        <div className="anim-splash">
          <SplashSVG />
          <span>-{damage}</span>
        </div>
      }
    </div>
  )
}

export default FlashDamage