import React from 'react'
import styles from './Card.module.css'

function Card(props) {
  const { style, image, children } = props

  return (
    <div className={styles.base} style={{ ...style, backgroundImage: `url(${image})` }}>
      <div className={styles.inner}>
        {children}
      </div>
    </div>
  )
}

function Cards(props) {
  return (
    <div className={styles.list}>
      {props.children}
    </div>
  )
}

export default Card
export { Cards }