import React from 'react'
import { withRouter } from 'react-router-dom'
import styles from './Header.module.css'
import PlayerProfile from 'core/features/player/components/PlayerProfile'
import PlayerCoins from 'core/features/player/components/PlayerCoins'

import icon from 'themes/default/red.PNG'
import icon2 from 'themes/default/exp.PNG'
import icon1 from 'themes/default/hero.PNG'

function Header(props) {
  return (
    <div className={styles.base}>
      <div>
        <div>
          <PlayerProfile />
        </div>
      </div>
      <div>
        <div className={styles.link} onClick={() => props.history.push('/')} style={{ backgroundImage: `url(${icon2})` }}>
          <span />
        </div>
        <div className={styles.link} onClick={() => props.history.push('/heroes')} style={{ backgroundImage: `url(${icon1})` }}>
          <span />
        </div>
        <div className={styles.link} onClick={() => props.history.push('/expedition')} style={{ backgroundImage: `url(${icon})` }}>
          <span />
        </div>
      </div>
      <div>
        <PlayerCoins />
      </div>
    </div>
  )
}

export default withRouter(Header)