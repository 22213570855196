// import foodPNG from './pot_icon.png'
// import metalPNG from './debris_icon.png'
import coinPNG from './coin_icon.png'
import metalPNG from './dagger_icon.png'
import stonePNG from './debris_icon.png'
import foodPNG from './berries_icon.png'

export default {
  coin: coinPNG,
  food: foodPNG,
  metal: metalPNG,
  stone: stonePNG,
}