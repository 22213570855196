import React from 'react'
import { connect } from 'react-redux'
import L from './layout/Layout'
import Header from './header/Header'
import Footer from './footer/Footer'

function Layout(props) {
  const { header, footer, title } = props.scene

  return (
    <L.Base>
      {header &&
        <L.Header>
          <Header />
        </L.Header>
      }

      <L.Content>
        {props.children}
      </L.Content>

      {footer &&
        <L.Footer>
          <h1>{title}</h1>

          <Header />
        </L.Footer>
      }
    </L.Base>
  )
}

export default connect(
  (state) => ({
    scene: state.game.scene,
  }),
  () => ({}),
)(Layout)