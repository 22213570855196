import React from 'react'
import styles from './Scene.module.css'

function Scene(props) {
  return (
    <div className={styles.base}>
      {props.children}
    </div>
  )
}

export default Scene