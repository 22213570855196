import React from "react";

function AbilityAnimation(props) {
  const { ability } = props;
  const animation = ability
    ? `anim-${ability.animation}${props.inverse ? "-inverse" : ""}`
    : "";
  const duration = ability ? ability.animationDuration : 0;

  return (
    <div className={animation} style={{ animationDuration: `${duration}ms` }}>
      {props.children}
    </div>
  );
}

export default AbilityAnimation;
