import React from 'react'
import Card from './Card'
import Icon from '../icons/Icon'
import { factions } from 'core/store/factions'
import styles from './HeroCard.module.css'
import classNames from 'classnames/bind'
import TransformNumber from 'core/helpers/TransformNumber'

const cx = classNames.bind(styles)

function HeroCard(props) {
  const {
    faction,
    name,
    avatar,
    price,
    battle,
    remainingHealth,
    remainingHealthPercent,
    remainingHealthPercentDelay,
    remainingManaPercent,
    showTitle,
  } = props

  return (
    <div className={cx({
      card: true,
      isForBattle: battle,
      showTitle,
    })} style={{ width: '20vh' }}>
      <Card
        image={factions[faction].background}
        style={{ height: '36vh' }}
      >
        <div className={styles.base}>
          <div className={styles.title}>
            {name}
          </div>

          <img className={styles.icon} src={avatar} alt="" />

          {battle &&
            <div className={styles.bars}>
              <div className={styles.healthbar}>
                <div className={styles.healthbarValueDelay} style={{ width: `${remainingHealthPercentDelay}%` }} />
                <div className={styles.healthbarValue} style={{ width: `${remainingHealthPercent}%` }} />
                <div className={styles.healthbarLabel}>{remainingHealth}</div>
              </div>
              <div className={styles.manabar}>
                <div className={styles.manabarValue} style={{ width: `${remainingManaPercent}%` }} />
              </div>
            </div>
          }
        </div>
      </Card>

      {price &&
        <div className={styles.footer}>
          <div>
            <Icon name="currency" />
            <div><TransformNumber value={price} /></div>
          </div>
          <div>
            Hire
          </div>
        </div>
      }
    </div>
  )
}

export default HeroCard