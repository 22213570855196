import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

const withGame = Component => props => {
  const { dispatch, staticContext, ...rest } = props
  return <Component {...rest} />
}

const mapProps = (state, props) => {
  const game = state.game

  return { game }
}

const mapActions = (dispatch) => {
  return {}
}

export default compose(
  connect(mapProps, mapActions),
  withGame,
)