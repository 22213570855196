// import foodPNG from './pot_icon.png'
// import metalPNG from './debris_icon.png'
import diamondPNG from './gem_01_icon.png'
import metalPNG from './dagger_icon.png'
import stonePNG from './debris_icon.png'
import foodPNG from './berries_icon.png'
import currencyIcon from './coin_icon.png'

export default {
  food: foodPNG,
  metal: metalPNG,
  stone: stonePNG,
  currency: currencyIcon,
  diamond: diamondPNG,
}