import React, { Component } from 'react'
import PlayerData from '../../data/PlayerData'
import DungeonData from './data/DungeonData'
import DungeonHeading from './components/DungeonHeading'
import DungeonArena from './components/DungeonArena'
import DungeonRewards from './components/DungeonRewards'
import '../../common/icons.css'
import './Dungeon.css'

class Dungeon extends Component {
  constructor(props) {
    super(props)

    this.stats = {
      maxDungeonLevel: 0,
      maxRoomsVisited: 0,
      maxEnemiesSlain: 0
    }

    console.log(DungeonData.newDungeon())

    this.state = {
      dungeon: DungeonData.newDungeon(),
      player: PlayerData.newPlayer(),
      rewards: [],
      gameStarted: false,
      rewardSelected: false,
      gettingRewards: false,
      enemyAttackDelay: 800,
      screenDelay: 1000,
      enemyWillAttack: true,
      cardFlipped: { player: false, enemy: false },
      autoFight: false,
      logger: [],
      stats: Object.assign({}, this.stats),
      highscores: Object.assign({}, this.stats)
    }

    this.autoInterval = null
    this.autoFrequency = 500

    this.getRewards = this.getRewards.bind(this)
    this.useAbility = this.useAbility.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleUnload = this.handleUnload.bind(this)
    this.setAutoFight = this.setAutoFight.bind(this)
    this.enemyTurn = this.enemyTurn.bind(this)
    this.startGame = this.startGame.bind(this)
    this.flipCard = this.flipCard.bind(this)
  }

  componentDidMount() {
    this.startGame()
    window.addEventListener('keypress', this.handleKeyPress, false)
    //window.addEventListener('beforeunload', this.handleUnload)
  }

  componentWillUnmount() {
    // if(this.state.gameStarted)  {
    //     if(window.confirm('You sure?')) {
    //         this.abandonGame()
    //     }
    // }

    window.removeEventListener('keypress', this.handleKeyPress, false)
    //window.removeEventListener('beforeunload', this.handleUnload)
  }

  /* -------------------------------------------------------- */

  startGame() {
    this.setState(prevState => ({
      gameStarted: true,
      stats: { ...prevState.stats, maxDungeonLevel: prevState.stats.maxDungeonLevel + 1 }
    }), this.nextRoom)
  }

  abandonGame() {
    console.log('abandonGame')
  }

  nextDungeon() {
    this.setState(prevState => ({
      dungeon: DungeonData.newDungeon(prevState.dungeon.level),
      stats: {
        ...prevState.stats,
        maxDungeonLevel: prevState.dungeon.level
      }
    }), this.nextRoom)
  }

  handleKeyPress(e) {
    switch (e.keyCode) {
      case 113: // Q
        this.useAbility(this.state.player.abilities[0])
        break

      case 119: // W
        this.useAbility(this.state.player.abilities[1])
        break

      case 101: // E
        this.useAbility(this.state.player.abilities[2])
        break

      case 114: // R
        this.useAbility(this.state.player.abilities[3])
        break

      case 32: // Space
        if (this.state.gameStarted) {
          this.setAutoFight()
        } else {
          this.restartGame()
        }
        break

      case 49: // 1
      case 50: // 2
      case 51: // 3
        if (this.state.gettingRewards) {
          this.getRewardFromKeys(e.keyCode)
        }
        break

      default:
        console.log(e.keyCode)
    }

    this.activateAbilityButton(e.keyCode)
  }

  handleUnload(e) {
    if (this.state.gameStarted) e.returnValue = 'test'
  }

  /* -------------------------------------------------------- */

  setAutoFight() {
    this.setState(prevState => ({
      autoFight: !prevState.autoFight
    }), this.autoFight)
  }

  autoFight() {
    this.clearAutoFight()

    if (this.state.autoFight) {
      this.autoInterval = setInterval(() => {
        this.useAbility(this.state.player.abilities[0])
      }, this.autoFrequency)
    }
  }

  clearAutoFight() {
    //
    if (this.autoInterval) clearInterval(this.autoInterval)
  }

  newHighscores(stats) {
    let highscores = Object.assign({}, this.state.highscores)

    Object.entries(stats).map(stat => {
      let key = stat[0]
      let value = stat[1]

      if (highscores[key] < value) highscores[key] = value

      return false
    })

    return highscores
  }

  /* -------------------------------------------------------- */

  nextRoom() {
    this.autoFight()

    this.setState(prevState => {
      let nextRoom = Math.round(prevState.dungeon.currentRoom + 1)

      return {
        dungeon: {
          ...prevState.dungeon,
          currentEnemy: prevState.dungeon.rooms[nextRoom - 1].enemy,
          currentRoom: nextRoom,
        },
        player: { ...prevState.player, damageArray: [] },
        stats: { ...prevState.stats, maxRoomsVisited: prevState.stats.maxRoomsVisited + 1 }
      }
    })
  }

  roomComplete() {
    this.setState(prevState => ({
      stats: {
        ...prevState.stats,
        maxEnemiesSlain: prevState.stats.maxEnemiesSlain + 1
      }
    }), this.nextRoom)
  }

  restartGame() {
    this.setState(prevState => {
      prevState.player.abilities.map(ability => {
        ability.onCooldown = 0
        return false
      })

      return {
        player: PlayerData.newPlayer(),
        dungeon: DungeonData.newDungeon(),
        stats: Object.assign({}, this.stats)
      }
    }, this.startGame)
  }

  calculateDamage(initiator, target) {
    // Calculate Damage Hit Change with Ability Power
    let hitChanceGamble = this.gamble
    let withAbility = initiator.stats.damage + (initiator.stats.damage * initiator.currentAbility.power.damage / 100)
    let withChance = withAbility * hitChanceGamble < initiator.stats.hitChance ? hitChanceGamble / 100 : 0
    let damagePower = Math.round(withAbility + withChance)

    // Calculate Final Damage with Crit Chance / Damage
    let critChanceGamble = this.gamble
    damagePower += critChanceGamble < initiator.stats.critChance ? Math.round(damagePower * initiator.stats.critDamage / 100) : 0

    // Calculate Remaining Health / Defence
    let remainingDefenceAfterHit = target.stats.defence - damagePower
    let remainingDefence = Math.max(0, remainingDefenceAfterHit)
    let damageOnHealth = remainingDefence > 0 ? 0 : Math.abs(remainingDefenceAfterHit) || damagePower
    let remainingHealh = Math.round(target.stats.health - damageOnHealth)

    return {
      damage: damagePower,
      remainingHealh,
      remainingDefence
    }
  }

  calculateLifesteal(initiator, damage) {
    return initiator.currentAbility.power.hasOwnProperty('lifesteal')
      ? Math.round(initiator.stats.health + (damage * initiator.currentAbility.power.lifesteal / 100))
      : initiator.stats.health
  }

  flipCard(type) {
    this.setState(prevState => {
      prevState.cardFlipped[type] = !prevState.cardFlipped[type]

      return {
        cardFlipped: prevState.cardFlipped
      }
    })
  }

  get gamble() {
    return Math.floor(Math.random() * 100)
  }

  /* -------------------------------------------------------- */

  get playerIsDead() {
    return this.state.player.stats.health <= 0 && this.state.player.stats.defence <= 0
  }

  get playerHasDefence() {
    return this.state.player.stats.defence > 0
  }

  get playerDamage() {
    return this.state.player.stats.damage
  }

  get playerAbilityPower() {
    return this.state.player.currentAbility.power
  }

  get playerHitChance() {
    return this.state.player.stats.hitChance
  }

  playerTurn() {
    if (this.state.player.currentAbility.power.hasOwnProperty('damage')) {
      this.playerIsDealingDamage({
        onEndTurn: this.enemyTurn
      })
    }

    if (this.state.player.currentAbility.power.hasOwnProperty('defence')) {
      this.playerIsGettingDefence({
        onEndTurn: this.enemyTurn
      })
    }

    this.setState(prevState => ({ player: { ...prevState.player, usingAbility: true } }))

    setTimeout(() => {
      this.setState(prevState => ({ player: { ...prevState.player, usingAbility: false } }))
    }, this.state.player.currentAbility.animationDuration)
  }

  playerIsDealingDamage(callback) {
    setTimeout(() => {
      this.enemyIsReceivingDamage(callback)
    }, Math.round(this.state.player.currentAbility.animationDuration / 2))
  }

  playerIsReceivingDamage() {
    let log = this.calculateDamage(this.state.dungeon.currentEnemy, this.state.player)

    this.setState(prevState => ({
      player: {
        ...prevState.player,
        stats: {
          ...prevState.player.stats,
          health: log.remainingHealh,
          defence: log.remainingDefence
        },
        damageArray: prevState.player.damageArray.concat([log.damage]),
        receivingDamage: true
      }
    }), () => {
      if (this.playerIsDead) {
        setTimeout(() => {
          this.dungeonEnd()
        }, this.state.screenDelay)
      }
    })

    setTimeout(() => {
      this.setState(prevState => ({
        dungeon: {
          ...prevState.dungeon,
          currentEnemy: {
            ...prevState.dungeon.currentEnemy,
            currentDamage: log.damage
          }
        },
        player: {
          ...prevState.player,
          receivingDamage: false
        }
      }))
    }, 200)
  }

  playerIsGettingDefence(callback) {
    this.setState(prevState => ({
      player: {
        ...prevState.player,
        stats: {
          ...prevState.player.stats,
          defence: Math.round(prevState.player.stats.defence + (prevState.player.stats.maxHealth * this.state.player.currentAbility.power.defence / 100))
        }
      }
    }))

    if ('onEndTurn' in callback) callback.onEndTurn()
  }

  /* -------------------------------------------------------- */

  get enemyIsDead() {
    return this.state.dungeon.currentEnemy.stats
      && this.state.dungeon.currentEnemy.stats.health <= 0
      && this.state.dungeon.currentEnemy.stats.defence <= 0
  }

  get enemyDamage() {
    return this.state.dungeon.currentEnemy.stats.damage
  }

  get enemyAbilityPower() {
    return this.state.dungeon.currentEnemy.currentAbility.power
  }

  get enemyHitChance() {
    return this.state.dungeon.currentEnemy.stats.hitChance
  }

  enemyTurn() {
    if (this.state.enemyWillAttack) {
      this.setState(prevState => ({
        player: { ...prevState.player, waitingTurn: true },
        dungeon: { ...prevState.dungeon, currentEnemy: { ...prevState.dungeon.currentEnemy, waitingTurn: false } }
      }))

      setTimeout(() => {
        this.enemyIsDealingDamage()
      }, this.state.enemyAttackDelay)
    }
  }

  enemyIsDealingDamage() {
    this.setState(prevState => ({
      dungeon: {
        ...prevState.dungeon,
        currentEnemy: { ...prevState.dungeon.currentEnemy, dealingDamage: true }
      }
    }))

    setTimeout(() => {
      this.setState(prevState => ({
        player: { ...prevState.player, waitingTurn: false },
        dungeon: { ...prevState.dungeon, currentEnemy: { ...prevState.dungeon.currentEnemy, dealingDamage: false, waitingTurn: true } }
      }))
    }, this.state.dungeon.currentEnemy.currentAbility.animationDuration)

    setTimeout(() => {
      this.playerIsReceivingDamage()
    }, Math.round(this.state.dungeon.currentEnemy.currentAbility.animationDuration / 2))
  }

  enemyIsReceivingDamage(callback) {
    this.setState(prevState => {
      let log = this.calculateDamage(prevState.player, prevState.dungeon.currentEnemy)
      let playerHealth = this.calculateLifesteal(prevState.player, log.damage)

      return {
        dungeon: {
          ...prevState.dungeon,
          currentEnemy: {
            ...prevState.dungeon.currentEnemy,
            stats: {
              ...prevState.dungeon.currentEnemy.stats,
              health: log.remainingHealh,
              defence: log.remainingDefence
            },
            damageArray: prevState.dungeon.currentEnemy.damageArray.concat([log.damage]),
            receivingDamage: true
          }
        },
        player: {
          ...prevState.player,
          currentDamage: log.damage,
          stats: {
            ...prevState.player.stats,
            health: playerHealth,
            maxHealth: prevState.player.stats.maxHealth < playerHealth ? playerHealth : prevState.player.stats.maxHealth,
          }
        }
      }
    }, () => {
      if (this.enemyIsDead) {
        setTimeout(() => {
          if (this.state.dungeon.currentRoom < this.state.dungeon.rooms.length) {
            this.roomComplete()
          } else {
            this.dungeonComplete()
          }
        }, this.state.screenDelay)
      } else {
        if ('onEndTurn' in callback) callback.onEndTurn()
      }
    })

    setTimeout(() => {
      this.setState(prevState => ({
        dungeon: {
          ...prevState.dungeon,
          currentEnemy: {
            ...prevState.dungeon.currentEnemy,
            receivingDamage: false
          }
        }
      }))
    }, 200)

    // this.logDamage({ text: Math.floor(Math.random()*10) })
  }

  /* -------------------------------------------------------- */

  canUseAbility(ability) {
    return !this.state.player.usingAbility
      && !this.playerIsDead
      && !this.state.dungeon.currentEnemy.dealingDamage
      && !this.state.player.waitingTurn
      && !this.enemyIsDead
      && ability.onCooldown < 1
  }

  useAbility(ability) {
    if (this.canUseAbility(ability)) {
      this.setState(prevState => {
        prevState.player.abilities.map(item => {
          return Math.min(0, item.onCooldown--)
        })

        let enemyWillAttack = prevState.enemyWillAttack
        ability.onCooldown = ability.cooldown

        let chance = this.gamble
        ability.onCooldown = chance <= ability.proc ? 0 : ability.cooldown
        enemyWillAttack = chance <= ability.proc ? false : true

        return {
          player: {
            ...prevState.player,
            currentAbility: ability
          },
          enemyWillAttack: enemyWillAttack
        }
      }, this.playerTurn)

      this.activateAbilityButton(ability.keyCode)
    }
  }

  activateAbilityButton(code) {
    let button = document.querySelector(`[data-keycode="${code}"]`)
    if (button) {
      button.classList.add('is-active')
      setTimeout(() => {
        button.classList.remove('is-active')
      }, 100)
    }
  }

  /* -------------------------------------------------------- */

  dungeonComplete() {
    this.clearAutoFight()

    this.setState(prevState => ({
      rewards: DungeonData.getRewards(prevState.dungeon.level),
      gettingRewards: true,
      dungeon: {
        ...prevState.dungeon,
        currentRoom: 0,
        image: DungeonData.lobbyImage()
      },
      stats: {
        ...prevState.stats,
        maxEnemiesSlain: prevState.stats.maxEnemiesSlain + 1
      }
    }), () => {
      if (this.state.autoFight) {
        setTimeout(() => {
          this.getRewards(this.state.rewards[0])
        }, 3000)
      }
    })
  }

  dungeonEnd() {
    this.setState(prevState => ({
      gameStarted: false,
      highscores: this.newHighscores(prevState.stats)
    }))
  }

  getRewards(reward) {
    if (this.state.rewardSelected) return

    this.setState(prevState => {
      let updatedPlayer = prevState.player
      updatedPlayer.stats[reward.enhancement] += reward.value

      if (reward.enhancement === 'health') {
        updatedPlayer.stats.maxHealth = prevState.player.stats.maxHealth < updatedPlayer.stats.health ? updatedPlayer.stats.health : prevState.player.stats.maxHealth
      }

      let rewards = prevState.rewards.map(rew => {
        if (rew === reward) {
          rew.active = !reward.active
        }

        return rew
      })

      return { rewards, player: updatedPlayer, rewardSelected: true }
    }, () => {
      setTimeout(() => {
        this.setState(prevState => ({
          rewards: [],
          rewardSelected: false,
          gettingRewards: false,
          dungeon: {
            ...prevState.dungeon,
            // image: DungeonData.setBackground(),
            level: prevState.dungeon.level + 1
          }
        }), this.nextDungeon)
      }, this.state.screenDelay * 2)
    })
  }

  getRewardFromKeys(key) {
    if (key === 49) {
      this.getRewards(this.state.rewards[0])
    }

    if (key === 50) {
      this.getRewards(this.state.rewards[1])
    }

    if (key === 51) {
      this.getRewards(this.state.rewards[2])
    }
  }

  logDamage(item) {
    this.setState(prevState => ({
      logger: [...prevState.logger, Object.assign({}, item)]
    }))
  }

  /* -------------------------------------------------------- */

  render() {
    return (
      <div className="dungeon-scene" style={{ backgroundImage: `url(${this.state.dungeon.image})` }}>
        <div
          className={`dungeon ${this.state.dungeon.currentEnemy.receivingDamage || this.state.rewardSelected ? 'anim-shake-low' : this.state.player.receivingDamage ? 'anim-shake-low' : ''}`}
          style={{ backgroundImage: `url(${this.state.dungeon.image})` }}>
          <div className="_overlay">
            <div className="_zone">
              {this.state.gameStarted &&
                <DungeonHeading {...this.state.dungeon} />}

              {!this.state.gameStarted &&
                <div className="_modal">
                  <div>
                    <h1>Dungeon Over</h1>
                    <p>Highscores</p>
                    <p><em>{this.state.stats.maxDungeonLevel} / {this.state.highscores.maxDungeonLevel}</em>Dungeon Level</p>
                    <p><em>{this.state.stats.maxEnemiesSlain} / {this.state.highscores.maxEnemiesSlain}</em>Enemies Slain</p>
                    <a className="button is-primary" onClick={() => { this.restartGame() }}>Restart</a>
                  </div>
                </div>}

              {this.state.gameStarted && !this.state.gettingRewards &&
                <DungeonArena
                  {...this.state}
                  flipCard={this.flipCard}
                  cardFlipped={this.state.cardFlipped}
                  playerIsDead={this.playerIsDead}
                  playerHasDefence={this.playerHasDefence}
                  enemyIsDead={this.enemyIsDead}
                  useAbility={this.useAbility}
                  setAutoFight={this.setAutoFight} />}

              {this.state.gameStarted && this.state.gettingRewards &&
                <DungeonRewards rewards={this.state.rewards} onClick={this.getRewards} />}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Dungeon