const initialState = {
  visible: false,
  current: null,
  data: null,
}

const modals = (state = initialState, action) => {
  switch (action.type) {
    case 'MODALS_OPEN':
      return {
        ...state,
        visible: true,
        current: action.payload.path,
        data: action.payload.data,
      }

    case 'MODALS_CLOSE':
      return {
        ...state,
        visible: false,
        current: null,
        data: null,
      }

    default:
      return state
  }
}

export default {
  modals
}