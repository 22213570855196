import React from 'react'
import withPlayer from 'core/features/player/hocs/withPlayer'
import styles from './PlayerProfile.module.css'

function PlayerProfile(props) {
  const { player } = props

  return (
    <div className={styles.base}>
      <div className={styles.avatar} style={{ backgroundImage: `url(${player.avatar})` }}>
        <div className={styles.frame} />
        <div className={styles.level}><span>{player.level}</span></div>
      </div>
      <div className={styles.info}>
        <div className={styles.name}>
          {player.name}
        </div>
        <div className={styles.progress}>
          <div className={styles.progressValue} />
        </div>
      </div>
    </div>
  )
}

export default withPlayer(PlayerProfile)