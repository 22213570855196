import characters from './data'

export const gamble = () => {
  return Math.floor(Math.random() * 100)
}

export const getRandomItem = (array) => {
  return array[Math.floor(Math.random() * array.length)]
}

export const randomCharacter = () => getRandomItem(characters)