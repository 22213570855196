import React from "react";
import { Card } from "core/ui";
import CharacterBattleCard from "./CharacterBattleCard";
import AbilityButton from "./AbilityButton";
import withBattleArena from "./withBattleArena";
import styles from "./BattleArena.module.css";
import classNames from "classnames/bind";

import img from "themes/default/grassland_bg.jpg";

const cx = classNames.bind(styles);

function BattleArena(props) {
  const cardBackground = img;
  const { player, arena, onAbility } = props;

  console.log("1", props);

  return (
    <div className={cx("base", {})}>
      <Card image={cardBackground} style={{ width: "120vh", height: "75vh" }}>
        <div className={styles.arena}>
          <div className={styles.player}>
            <CharacterBattleCard key="player" {...arena.player} />
          </div>

          <div className={styles.enemy}>
            <CharacterBattleCard key="enemy" {...arena.enemy} />
          </div>
        </div>

        <div className={styles.bottom}>
          <div className={styles.abilities}>
            <div>
              {player.abilities.map(ability => (
                <AbilityButton
                  key={ability.id}
                  {...ability}
                  onClick={() => onAbility(ability)}
                />
              ))}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default withBattleArena(BattleArena);
