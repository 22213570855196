import model from "./model";

import icon02 from "themes/default/skill.PNG";
import icon01 from "themes/default/strike.PNG";

// Stealth, you can do damage, but evade all attacks
// idea: opacity: .2

export default {
  "ability-strike": model({
    name: "Strike",
    level: 1,
    power: { damage: 100 },
    proc: 0,
    cooldown: 0,
    icon: icon01,
    animation: "ability-strike",
    animationDuration: 800,
  }),

  "ability-shield": model({
    name: "Shield",
    level: 3,
    power: { damage: 100, shield: 220 },
    proc: 30,
    cooldown: 2,
    icon: icon02,
    animationDuration: 800,
  }),

  "ability-execute": model({
    name: "Execute",
    level: 3,
    power: { damage: 220, lifesteal: 30 },
    proc: 30,
    cooldown: 2,
    icon: icon02,
    animationDuration: 600,
  }),
};
