import React from 'react'
import styles from './Layout.module.css'

const Layout = (props) => (
  <div className={styles.base}>
    {props.children}
  </div>
)

const LayoutHeader = (props) => (
  <div className={styles.header}>
    {props.children}
  </div>
)

const LayoutContent = (props) => (
  <div className={styles.content}>
    {props.children}
  </div>
)

const LayoutFooter = (props) => (
  <div className={styles.footer}>
    {props.children}
  </div>
)

const LayoutSidebarLeft = (props) => (
  <div className={styles.sidebarLeft}>
    {props.children}
  </div>
)

const LayoutSidebarRight = (props) => (
  <div className={styles.sidebarRight}>
    {props.children}
  </div>
)

export default {
  Base: Layout,
  Header: LayoutHeader,
  Content: LayoutContent,
  Footer: LayoutFooter,
  Sidebar: {
    Left: LayoutSidebarLeft,
    Right: LayoutSidebarRight,
  }
}