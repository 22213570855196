import React from 'react'
import { Modal } from 'core/ui'
import withModals from 'core/features/modals/hocs/withModals'
import BattleArena from 'games/battlearena/BattleArena'

function Test(props) {
  const { closeModal } = props

  return (
    <Modal>
      <BattleArena />
    </Modal>

  )
}

export default withModals(Test)