import React from 'react'
import withModals from '../hocs/withModals'

function Switch(props) {
  console.log('modals:switch', props)
  return (
    React.Children.map(props.children, child => {
      return child.props.path === props.modals.current ? child : null
    })
  )
}

export default withModals(Switch)
