import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Layout } from './layout'
import { ModalsSwitch, Modal } from 'core/features/modals'
import { scenes } from './store/scenes'
import { modals } from './store/modals'
import './normalize.css'
import './index.css'
import 'themes/default/animations.css'

function Game() {
  return (
    <>
      <Layout>
        <Switch>
          {scenes.map(scene => (
            <Route key={scene.route.path} {...scene.route} />
          ))}
        </Switch>
      </Layout>

      <ModalsSwitch>
        {modals.map(modal => (
          <Modal key={modal.path} {...modal} />
        ))}
      </ModalsSwitch>
    </>
  )
}

export default Game
