import React from "react";
import { Scene } from "core/layout";
import Dungeon from "../games/dungeon/minigames/endless/Dungeon";
import { Cards, ZoneCard } from "core/ui";
import withModals from "core/features/modals/hocs/withModals";

import img01 from "themes/default/grassland_bg.jpg";
import img02 from "themes/default/desert_bg.jpg";
import img03 from "themes/default/cave_bg.jpg";
import icon01 from "themes/default/lens_icon.png";
import icon02 from "themes/default/flask_icon.png";
import icon03 from "themes/default/skull_icon.png";

function Expedition(props) {
  return (
    <Scene title="Expedition">
      <Cards>
        <ZoneCard image={img01} icon={icon01} title="Quest" />
        <ZoneCard
          image={img02}
          icon={icon02}
          title="Adventure"
          onClick={() => props.openModal("test")}
        />
        <ZoneCard image={img03} icon={icon03} title="Endless" />
      </Cards>
      {/* <Dungeon /> */}
    </Scene>
  );
}

export default withModals(Expedition);
