import { combineReducers } from 'redux'

export const initialState = {
  player: {
    name: null,
    faction: null,
    avatar: null,

    damage: 40,
    health: 300,

    lastDamageReceived: 0,
    remainingHealth: 300,
    remainingHealthPercent: 100,
    remainingHealthPercentDelay: 100,
    remainingMana: 300,
    remainingManaPercent: 100,

    turn: 0,
    myTurn: false,
    isPlayer: true,
    isDead: false,
    isDealingDamage: false,
    isReceivingDamage: false,
  },
  enemy: {
    name: null,
    faction: null,
    avatar: null,

    damage: 40,
    health: 300,

    lastDamageReceived: 0,
    remainingHealth: 300,
    remainingHealthPercent: 100,
    remainingHealthPercentDelay: 100,
    remainingMana: 300,
    remainingManaPercent: 100,

    turn: 0,
    myTurn: false,
    isEnemy: true,
    isDead: false,
    isDealingDamage: false,
    isReceivingDamage: false,
  }
}

const receiveDamage = (state, action) => {
  const damage = action.ability.power.damage
  const remainingHealth = state.remainingHealth - damage
  const remainingHealthPercent = remainingHealth > 0 ? remainingHealth / state.health * 100 : 0

  return {
    ...state,
    remainingHealth,
    remainingHealthPercent,
    lastDamageReceived: damage,
    isReceivingDamage: true,
    isDead: remainingHealth <= 0,
  }
}

const player = (state = {}, action) => {
  switch (action.type) {
    case 'PLAYER_SET':
      return {
        ...state,
        ...action.player,
      }

    case 'PLAYER_TURN':
      return {
        ...state,
        myTurn: true,
        turn: state.turn + 1,
      }

    case 'PLAYER_IN':
      return {
        ...state,
        isDealingDamage: true,
        ability: action.ability,
      }

    case 'PLAYER_OUT':
      return {
        ...state,
        isDealingDamage: false,
        ability: null,
      }

    case 'PLAYER_COLLISION':
      return receiveDamage(state, action)

    case 'ENEMY_TURN':
      return {
        ...state,
        myTurn: false,
      }

    case 'ENEMY_IN':
      return state

    case 'ENEMY_OUT':
      return {
        ...state,
        isReceivingDamage: false,
        remainingHealthPercentDelay: state.remainingHealthPercent,
      }

    default:
      return state
  }
}

const enemy = (state = {}, action) => {
  switch (action.type) {
    case 'ENEMY_SET':
      return {
        ...state,
        ...action.enemy,
      }

    case 'ENEMY_TURN':
      return {
        ...state,
        myTurn: true,
        turn: state.turn + 1,
      }

    case 'ENEMY_IN':
      return {
        ...state,
        myTurn: true,
        isDealingDamage: true,
        ability: action.ability,
      }

    case 'ENEMY_OUT':
      return {
        ...state,
        myTurn: false,
        isDealingDamage: false,
        ability: null,
      }

    case 'ENEMY_COLLISION':
      return receiveDamage(state, action)

    case 'PLAYER_TURN':
      return {
        ...state,
        myTurn: false,
      }

    case 'PLAYER_IN':
      return state

    case 'PLAYER_OUT':
      return {
        ...state,
        isReceivingDamage: false,
        remainingHealthPercentDelay: state.remainingHealthPercent,
      }

    default:
      return state
  }
}

export default combineReducers({
  player,
  enemy,
})