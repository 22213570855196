import React from 'react'
import ReactDOM from 'react-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import * as serviceWorker from './core/serviceWorker'
import { Game, configureStore, configureSaga } from './core'
import { stores, sagas } from './games'

const store = configureStore(stores, {})
configureSaga(sagas)

ReactDOM.render(
  <ReduxProvider store={store}>
    <BrowserRouter>
      <Game />
    </BrowserRouter>
  </ReduxProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
