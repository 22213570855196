import React from 'react'
import withPlayer from 'core/features/player/hocs/withPlayer'
import styles from './PlayerCoins.module.css'
import { Icon } from 'core/ui'
import TransformNumber from 'core/helpers/TransformNumber'

function PlayerCoins(props) {
  const { player } = props

  return (
    <div className={styles.base}>
      <div className={styles.frame}>
        <div className={styles.icon}>
          <Icon name="food" id="food-inventory" />
        </div>
        <div className={styles.amount}>
          <TransformNumber value={player.coins} />
        </div>
      </div>
      <div className={styles.frame}>
        <div className={styles.icon}>
          <Icon name="stone" id="stone-inventory" />
        </div>
        <div className={styles.amount}>
          <TransformNumber value={player.coins} />
        </div>
      </div>
      <div className={styles.frame}>
        <div className={styles.icon}>
          <Icon name="metal" id="metal-inventory" />
        </div>
        <div className={styles.amount}>
          <TransformNumber value={player.coins} />
        </div>
      </div>

      <div className={styles.frame}>
        <div className={styles.icon}>
          <Icon name="currency" id="coin-inventory" />
        </div>
        <div className={styles.amount}>
          <TransformNumber value={player.coins} />
        </div>
      </div>
      <div className={styles.frame}>
        <div className={styles.icon}>
          <Icon name="diamond" />
        </div>
        <div className={styles.amount}>
          <TransformNumber value={player.diamonds} />
        </div>
      </div>
    </div>
  )
}

export default withPlayer(PlayerCoins)