import React, { useState, useRef } from 'react'
import styles from './Building.module.css'
// import classNames from 'classnames/bind'

import { createAnimation } from 'core/helpers'
import ProgressBar from '../shared/ProgressBar'
import useInterval from '../shared/hooks/useInterval'
import Icon from '../shared/icons/Icon'

// const cx = classNames.bind(styles)

function Building(props) {
  const [progress, setProgress] = useState(0)
  const [loot, setLoot] = useState(0)
  const [isRunning, setIsRunning] = useState(true)
  const [lootPercent, setLootPercent] = useState(0)
  const iconEl = useRef(null)

  useInterval(() => {
    if (loot >= props.limit) {
      setIsRunning(false)
      return
    }

    setProgress(progress + props.speed)

    if (progress >= 100) {
      const newLoot = loot + props.amount
      setLoot(newLoot)
      setLootPercent(newLoot / props.limit * 100)
      setProgress(0)
    }
  }, isRunning ? 100 : null)

  const handleClick = e => {
    props.onCollect(loot)
    setLoot(0)
    setLootPercent(0)
    setIsRunning(true)
    createAnimation(iconEl, props.icon, loot / props.limit * 100)
  }

  return (
    <div className={styles.base}>
      <div className={styles.card}>
        <div className={styles.name}>{props.name}</div>
        <div className={styles.progress}>
          <ProgressBar value={progress} />
        </div>
      </div>
      <div className={styles.notification} onClick={handleClick}>
        <div className={styles.notificationIcon} ref={iconEl}><Icon name={props.icon} /></div>
        <div className={styles.notificationProgress} style={{ transform: `translate3d(0,-${lootPercent}%,0)` }} />
      </div>
    </div>
  )
}

export default Building