import React from 'react'
import { Scene } from 'core/layout'
import NewGame from 'games/newgame/NewGame'

function Town(props) {
  return (
    <Scene title="Town">
      <NewGame />
    </Scene>
  )
}

export default Town