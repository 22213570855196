import React, { useEffect } from 'react'
import withModals from '../hocs/withModals'

function Modal(props) {
  useEffect(() => {
    document.querySelector('html').classList.add('hide-scroll')

    return () => {
      document.querySelector('html').classList.remove('hide-scroll')
    }
  }, [])

  if (props.modals.current !== props.path) return null

  return <props.component {...props} data={props.modals.data} />
}

export default withModals(Modal)
