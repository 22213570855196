import model from './model'

import hero01 from 'themes/default/FantasyCharacters_elf_sentinel_female.png'
import hero02 from 'themes/default/FantasyCharacters_elf_sentinel_male.png'
import hero03 from 'themes/default/FantasyCharacters_drow_warrior_male.png'
import hero04 from 'themes/default/FantasyCharacters_Dwarf_warrior_male.png'
import hero05 from 'themes/default/FantasyCharacters_Dwarven_healer_female.png'
import hero06 from 'themes/default/FantasyCharacters_h_warrior_male.png'
import hero07 from 'themes/default/FantasyCharacters_h_rogue_male.png'
import hero08 from 'themes/default/FantasyCharacters_h_mage_male.png'
import hero09 from 'themes/default/FantasyCharacters_h_warrior_female.png'

// let enemyNames = ['Eyewhip', 'Kakuzu', 'Stok', 'Ovidius', 'Danisha', 'Victorius', 'Skymaul', 'Clearbeard', 'Havencleaver', 'Fuseeyes', 'Saursworn', 'Riverscribe', 'Laughingglory', 'Grassguard', 'Crestlance', 'Bronzesnow', 'Orbmourn', 'Longorb', 'Windless', 'Distantsky', 'Feathersorrow', 'Barleysong', 'Sharpmore', 'Nighttrap', 'Mourninghammer', 'Truebinder', 'Horsewater', 'Bloodlance', 'Slatekeeper', 'Glowgem', 'Emberfeather', 'Keenforest', 'Downsorrow', 'Voidcleaver', 'Doomorb', 'Windshot', 'Steelmantle', 'Lowgrain', 'Foretaker', 'Thundercutter', 'Regaldust', 'Clawflaw', 'Truthhand', 'Dirgeshaper', 'Marshstrength', 'Autumnsnarl']

export default [
  model({
    id: 'hero-skymaul',
    name: 'Skymaul',
    faction: 'wildlands',
    avatar: hero09,
  }),
  model({
    id: 'hero-clearbeard',
    name: 'Clearbeard',
    faction: 'wildlands',
    avatar: hero04,
  }),
  model({
    id: 'hero-havencleaver',
    name: 'Havencleaver',
    faction: 'wildlands',
    avatar: hero06,
  }),
  model({
    id: 'hero-saursworn',
    name: 'Saursworn',
    faction: 'underlands',
    avatar: hero03,
  }),
  model({
    id: 'hero-fuseeyes',
    name: 'Fuseeyes',
    faction: 'underlands',
    avatar: hero08,
  }),
  model({
    id: 'hero-riverscribe',
    name: 'Riverscribe',
    faction: 'greenlands',
    avatar: hero05,
  }),
  model({
    id: 'hero-laughingglory',
    name: 'Laughingglory',
    faction: 'underlands',
    avatar: hero07,
  }),
  model({
    id: 'hero-grassguard',
    name: 'Grassguard',
    faction: 'greenlands',
    avatar: hero02,
  }),
  model({
    id: 'hero-crestlance',
    name: 'Crestlance',
    faction: 'greenlands',
    avatar: hero01,
  }),
]